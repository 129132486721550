import 'regenerator-runtime/runtime';
import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from 'styled-components';
import { AppWrapper } from '@zillow/page-frame-next-components';
import { merge } from 'lodash';
import { page, setdim } from '@zillow/universal-analytics';
import {
    ThemeSkunkWorksCmsConstellation,
    ThemeCmsConstellation,
} from '@zillow/zrs-cms';
import { appendZhlParams } from '@zillow/mortgage-urls';
import { PagePropsProvider, getCmsInfoForPage } from '@zillow/zrs-cms';
import {
    NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_SERVICE_VERSION,
    NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE,
    clientProfilerConfig,
} from '../../server/config';
import { trackExposureEvent } from '../util/optimizely';
import VisualRefreshFonts from '../components/VisualRefreshFonts';

function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return (
        typeof window !== 'undefined' &&
        toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
    );
}

if (typeof window !== 'undefined') {
    datadogRum.init({
        applicationId: '670a99d8-3d41-4858-ae1f-cb66797af887',
        clientToken: 'pubb1845c6bcbfd371c2a207a83befcffc0',
        site: 'datadoghq.com',
        service: 'cms-constellation',
        env: NEXT_PUBLIC_ENV,
        version: NEXT_PUBLIC_SERVICE_VERSION,
        sessionSampleRate: parseInt(NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE),
        startSessionReplayRecordingManually: true,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

const MyApp = ({ Component, pageProps }) => {
    useEffect(() => {
        // For how-much-is-my-home-worth analytics
        const params = new URLSearchParams(window.location.search);
        if (params.has('zpid')) {
            setdim({ dimension2: params.get('zpid') });
        }
        // https://gitlab.zgtools.net/zillow/npm/universal-analytics/-/blob/master/README-NEWLANE.md#pagepath-properties-options
        // https://dataportal.zillowgroup.net/event-registry/2284
        page(
            window.location.pathname,
            {},
            {
                newLaneEvent: {
                    envelope: {
                        event_type_id: '2284',
                        event_type_version_id: '1',
                        event_template_id: '126',
                        event_template_version_id: '1',
                    },
                    clickstream_trigger: {
                        trigger_type_nm: 'view',
                        trigger_location_nm: 'cms',
                        trigger_source_nm: 'cms',
                        trigger_object_nm: 'no_trigger_object',
                    },
                    semantic: {
                        semantic_event_nm: 'view_content',
                    },
                    cms_info: getCmsInfoForPage(pageProps),
                },
            }
        );

        window.addEventListener('load', () => {
            appendZhlParams(document);
        });
    }, []);

    // Disable Chrome's behavior where if the current user has already visited
    // the page and has scrolled to a given point, Chrome will restore that
    // scroll position and ignore the deep link.
    useEffect(() => {
        if ('scrollRestoration' in window.history && window.location.hash) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    // Track Optimizely exposure events with Clickstream.
    useEffect(() => {
        trackExposureEvent();
    }, []);

    return (
        <AppWrapper
            clientProfilerConfig={merge(clientProfilerConfig, {
                staticDimensions: {
                    platform: isMobile() ? 'mobile' : 'desktop',
                },
            })}
        >
            <ThemeProvider
                theme={
                    pageProps.useVisualRefreshTheme
                        ? ThemeSkunkWorksCmsConstellation
                        : ThemeCmsConstellation
                }
            >
                <PagePropsProvider pageProps={pageProps}>
                    {pageProps.useVisualRefreshTheme && <VisualRefreshFonts />}
                    <Component {...pageProps} />
                </PagePropsProvider>
            </ThemeProvider>
        </AppWrapper>
    );
};

export default MyApp;
