import React from 'react';

import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';

const ObjectSans = createGlobalStyle`
    @font-face {
        font-family: 'Object Sans';
        src: url('https://www.zillowstatic.com/s3/constellation-website/public/shared/fonts/object-sans/latest/object-sans-regular.woff2')
            format('woff');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Object Sans';
        src: url('https://www.zillowstatic.com/s3/constellation-website/public/shared/fonts/object-sans/latest/object-sans-bold.woff2')
            format('woff');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Object Sans';
        src: url('https://www.zillowstatic.com/s3/constellation-website/public/shared/fonts/object-sans/latest/object-sans-heavy.woff2')
            format('woff');
        font-weight: 900;
        font-style: normal;
    }
`;

const VisualRefreshFonts = () => (
    <React.Fragment>
        <Head>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                rel="stylesheet"
            />
            <link rel="preconnect" href="https://www.zillowstatic.com" />
        </Head>
        <ObjectSans />
    </React.Fragment>
);

export default VisualRefreshFonts;
