import { event } from '@zillow/universal-analytics';
import { getGuid, getZuid } from './cookies';

export function trackExposureEvent() {
    const experimentStates =
        window.optimizely && typeof window.optimizely.get === 'function'
            ? window.optimizely.get('state')
            : null;

    const experiments =
        experimentStates &&
        typeof experimentStates.getExperimentStates === 'function'
            ? experimentStates.getExperimentStates()
            : null;

    if (experiments && typeof experiments === 'object') {
        // Build an array of active Optimizely treatments and their variation.
        const treatments = Array();
        for (const key in experiments) {
            const experiment = experiments[key];
            // We only want to track active experiments.
            if (experiment.hasOwnProperty('isActive') && experiment.isActive) {
                if (
                    experiment.hasOwnProperty('id') &&
                    experiment.hasOwnProperty('variation') &&
                    experiment.variation
                ) {
                    let treatmentName = `OPTIMIZELY_${experiment.id}`;
                    // If a variation name contains the word “control” (any case), we will use CONTROL as the variation name.
                    const variationName = experiment.variation.name;
                    if (variationName.toLowerCase().includes('control')) {
                        treatmentName = treatmentName + '.CONTROL';
                    } else {
                        treatmentName =
                            treatmentName + '.V_' + experiment.variation.id;
                    }
                    treatments.push(treatmentName);
                }
            }
        }
        // Set randomizationKey to the ZUID if exists, or a GUID if not.
        let keyType = 'zuid';
        let randomizationKey = getZuid();
        if (
            typeof randomizationKey !== 'string' ||
            randomizationKey.toLowerCase() === 'null'
        ) {
            keyType = 'guid';
            randomizationKey = getGuid();
        }
        // Track exposure events if treatments exist and randomizationKey is set.
        if (
            typeof randomizationKey === 'string' &&
            randomizationKey.toLowerCase() !== 'null' &&
            treatments.length > 0
        ) {
            // https://dataportal.zillowgroup.net/event-registry/5485
            event({
                envelope: {
                    event_type_id: '5485',
                    event_type_version_id: '1',
                    event_template_id: '3',
                    event_template_version_id: '1',
                },
                semantic: {
                    semantic_event_nm: 'optimizely_exposure',
                    topic_tag_txt: ['cms', 'optimizely'],
                },
                exposure: {
                    assignment_service_cd: 'optimizely',
                    key_type_cd: keyType,
                    randomization_key: randomizationKey,
                    treatment_nm: treatments,
                },
            });
        }
    }
}
