import { ThemeConstellation, FONT_TYPES, ThemeSkunkWorks } from '@zillow/constellation';

var ThemeCmsConstellation = Object.assign({}, ThemeConstellation, {
  constellation: Object.assign({}, ThemeConstellation.constellation, {
    colors: Object.assign({}, ThemeConstellation.constellation.colors, {
      teal100: '#C8FBF2',
      teal200: '#9EF9EA',
      teal300: '#59F0DC',
      teal400: '#2DCFBC',
      teal500: '#21A495',
      teal600: '#136F65',
      backgroundBrand: ThemeConstellation.constellation.colors.blue400,
      backgroundDarkPurple: ThemeConstellation.constellation.colors.purple600,
      backgroundMedium: ThemeConstellation.constellation.colors.coolGray200,
      backgroundTeal: '#136F65',
      textAqua: ThemeConstellation.constellation.colors.aqua300,
      textDarkBlue: ThemeConstellation.constellation.colors.blue600,
      textGreen: ThemeConstellation.constellation.colors.green300,
      textPurple: ThemeConstellation.constellation.colors.purple300
    }),
    fontSizes: Object.assign({}, ThemeConstellation.constellation.fontSizes, {
      large: ThemeConstellation.constellation.fontSizes[FONT_TYPES.h5]
    }),
    fontWeights: Object.assign({}, ThemeConstellation.constellation.fontWeights, {
      large: ThemeConstellation.constellation.fontWeights[FONT_TYPES.body]
    }),
    lineHeights: Object.assign({}, ThemeConstellation.constellation.lineHeights, {
      large: ThemeConstellation.constellation.lineHeights[FONT_TYPES.h5]
    }),
    spacing: Object.assign({}, ThemeConstellation.constellation.spacing, {
      xxl: 64
    }),
    radii: Object.assign({}, ThemeConstellation.constellation.radii, {
      xl: 16,
      xxl: 24
    })
  })
});
var ThemeSkunkWorksCmsConstellation = Object.assign({}, ThemeSkunkWorks, {
  constellation: Object.assign({}, ThemeSkunkWorks.constellation, {
    colors: Object.assign({}, ThemeSkunkWorks.constellation.colors, {
      backgroundBrand: ThemeSkunkWorks.constellation.colors.blue400,
      textDarkBlue: ThemeSkunkWorks.constellation.colors.blue600,
      teal100: '#C8FBF2',
      teal200: '#9EF9EA',
      teal300: '#59F0DC',
      teal400: '#2DCFBC',
      teal500: '#21A495',
      teal600: '#136F65',
      backgroundTeal: '#136F65',
      backgroundDarkPurple: ThemeSkunkWorks.constellation.colors.purple600,
      textAqua: ThemeSkunkWorks.constellation.colors.aqua300,
      textGreen: ThemeSkunkWorks.constellation.colors.green300,
      textPurple: ThemeSkunkWorks.constellation.colors.purple300
    }),
    fontSizes: Object.assign({}, ThemeSkunkWorks.constellation.fontSizes, {
      large: ThemeSkunkWorks.constellation.fontSizes[FONT_TYPES.h5]
    }),
    fontWeights: Object.assign({}, ThemeSkunkWorks.constellation.fontWeights, {
      large: ThemeSkunkWorks.constellation.fontWeights[FONT_TYPES.body]
    }),
    lineHeights: Object.assign({}, ThemeSkunkWorks.constellation.lineHeights, {
      large: ThemeSkunkWorks.constellation.lineHeights[FONT_TYPES.h5]
    }),
    spacing: Object.assign({}, ThemeSkunkWorks.constellation.spacing, {
      xxl: 64
    }),
    radii: Object.assign({}, ThemeSkunkWorks.constellation.radii, {
      xl: 16,
      xxl: 24
    })
  })
});
var ThemeCmsConstellation$1 = ThemeCmsConstellation;

export { ThemeSkunkWorksCmsConstellation, ThemeCmsConstellation$1 as default };
