import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() || {};

export const PUBLIC_ENV_TO_CLIENT_PROFILER_ENV_MAP = {
    prod: 'production',
    test: 'tes_100',
    local: 'local_cp_env',
};

export const {
    WP_GRAPHQL = 'http://zillow.local/constellation/graphql/',
    ENV = 'local',
    NODE_ENV = 'development',
    SERVICE_NAME = 'cms-constellation',
    // set assetPrefix to blank to resolve assets locally else injected when deployed to the pod
    STATICS_CDN = '',
    NEXT_PUBLIC_CLICKSTREAM_HOST_PORT:
        CLICKSTREAM_HOST_PORT = 'analytics-zg-api.test.zillow.net:443',
    NEXT_PUBLIC_CLIENT_PROFILER_HOST_ID:
        CLIENT_PROFILER_HOST_ID = 'qIi8aKMyyQ4',
    NEXT_PUBLIC_SERVICE_VERSION: SERVICE_VERSION,
    // optimizely project id
    NEXT_PUBLIC_OPTIMIZELY_ID = '',
    NEXT_PUBLIC_APEX_ENDPOINT = '',
    NEXT_PUBLIC_KNOTCH_LC_LAYOUT_ACCOUNT_ID = '',
    NEXT_PUBLIC_KNOTCH_LC_POST_ACCOUNT_ID = '',
    NEXT_PUBLIC_BEDROCK_STATIC_DOMAIN = '',
    NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE = '0',
} = publicRuntimeConfig ?? process.env;

export const {
    CMS_BEDROCK_URL = '',
    CMS_BEDROCK_API_KEY = '',
    SSID_INFO_URL = '',
    SSID_INFO_KEY = '',
    S3S_SERVICE = 'http://s3s.qa.zillow.net',
    PAGE_FRAME_SERVICE = '',
    REDIS_HOST = '',
    REDIS_PORT = '6379',
    REVALIDATION_INTERVAL: DEFAULT_REVALIDATION_INTERVAL = '1500',
} = serverRuntimeConfig ?? process.env;

const { NEXT_PUBLIC_ENV: DEFAULT_NEXT_PUBLIC_ENV = 'local' } =
    publicRuntimeConfig ?? process.env;

export const REVALIDATION_INTERVAL = parseInt(
    DEFAULT_REVALIDATION_INTERVAL,
    10
);

export const SERVICE_PORT = parseInt(process.env.PORT ?? '8000', 10);
export const NEXT_PUBLIC_ENV = DEFAULT_NEXT_PUBLIC_ENV ?? ENV;

export const clientProfilerConfig = {
    beaconUrl: `https://${CLICKSTREAM_HOST_PORT}/metrics/rum`,
    staticDimensions: {
        environment:
            PUBLIC_ENV_TO_CLIENT_PROFILER_ENV_MAP[NEXT_PUBLIC_ENV] ||
            NEXT_PUBLIC_ENV,
        serviceVersion: SERVICE_VERSION,
        hostId: CLIENT_PROFILER_HOST_ID,
        packageName: 'cms-constellation',
        pageName: 'CMS:Constellation',
    },
    trackedEventDetails: {
        eventDetails: [
            {
                TTFB: {
                    dynamicEvent: false,
                    graphiteName: 'TTFB',
                    thresholds: {
                        delighted: 600,
                        satisfied: 1000,
                    },
                },
            },
        ],
    },
};
