/**
 * Retrieves the ZUID from the zjs_user_id cookie.
 * Strips out any non-alphanumeric characters.
 *
 * @return {string} The ZUID extracted from the cookie value, or null if not found.
 */
export function getZuid() {
    const value = getCookieValue('zjs_user_id');
    const zuid = value ? value.replace(/[^A-Za-z0-9\-_]/g, '') : null;
    return zuid;
}

/**
 * Retrieves globally unique identifier (GUID) by extracting it from the zguid cookie.
 * GUID is found after the 24|$ prefix.
 *
 * @return {string} The extracted GUID, or null if not found.
 */
export function getGuid() {
    const value = getCookieValue('zguid');
    const guid = value ? value.replace('24|$', '') : null;
    return guid;
}

/**
 * Retrieves the URL decoded value of a specific cookie from the document's cookies.
 *
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @return {string | null} The value of the specified cookie, or null if the cookie is not found.
 */
export function getCookieValue(cookieName: string): string | null {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const [name, value] = cookie.split('=');
        if (name === cookieName) {
            return decodeURIComponent(value);
        }
    }
    return null;
}
