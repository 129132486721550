function getCmsInfoForPage(pageProps) {
  var _categories, _tags, _pageIdentifiers;
  if (!pageProps) {
    return {};
  }
  var cmsInfo = {};
  var categories;
  var tags;
  var pageIdentifiers;
  if ('post' in pageProps) {
    var _pageProps$post;
    categories = pageProps.post.categories;
    tags = pageProps.post.postTags;
    pageIdentifiers = ((_pageProps$post = pageProps.post) === null || _pageProps$post === void 0 ? void 0 : _pageProps$post.pageIdentifiers) || [];
  } else if ('category' in pageProps) {
    categories = [{
      slug: pageProps.category.slug
    }];
  } else if ('tag' in pageProps) {
    tags = [{
      slug: pageProps.tag.slug
    }];
  }
  // "Pages" don't have tags or categories.
  if ('page' in pageProps) {
    var _pageProps$page;
    pageIdentifiers = ((_pageProps$page = pageProps.page) === null || _pageProps$page === void 0 ? void 0 : _pageProps$page.pageIdentifiers) || [];
  }
  if ((_categories = categories) !== null && _categories !== void 0 && _categories.length) {
    cmsInfo.taxonomy_categories_txt = categories.map(function (category) {
      return category.slug;
    });
  }
  if ((_tags = tags) !== null && _tags !== void 0 && _tags.length) {
    cmsInfo.taxonomy_tags_txt = tags.map(function (tag) {
      return tag.slug;
    });
  }
  if ((_pageIdentifiers = pageIdentifiers) !== null && _pageIdentifiers !== void 0 && _pageIdentifiers.length) {
    cmsInfo.taxonomy_page_identifier_txt = pageIdentifiers.map(function (pageIdentifier) {
      return pageIdentifier.slug;
    });
  }
  return cmsInfo;
}

export { getCmsInfoForPage };
